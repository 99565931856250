import moment from "moment";

const fields = {
};

const services = {
    login: '/login',
    inscription: '/inscription',
    checkSession: '/getSessionUser',
    user: {
        list: '/private/user/list',
        create: '/private/user/create',
        update: '/private/user/update',
        delete: '/private/user/remove',
    },
    etudiantDossier: '/private/etudiant/dossier',
    etudiant: {
        checkTokenValidation: '/etudiant/checkTokenValidation',
        resetPassword: '/etudiant/resetpassword',
        generateToken: '/etudiant/generateToken',
        changePassword: '/private/etudiant/change-password',
        update: '/private/etudiant/update'
    },
    attachement: {
        list: '/private/attachement/list',
        create: '/private/attachement/create',
        update: '/private/attachement/update',
        delete: '/private/attachement/remove',
        upload: '/private/attachement/upload',
    },
    demandeSoutenance: {
        list: '/private/demande-soutenance/list',
        create: '/private/demande-soutenance/create',
        update: '/private/demande-soutenance/update',
        delete: '/private/demande-soutenance/remove'
    },
    dossier: {
        addProcess: '/private/dossier/addProcess',
        update: '/private/dossier/update'
    },
    action: {
        list: '/private/action/list'
    },
    upload: '/private/upload',
    deleteFile: '/private/upload/delete',
    generatePDF: '/private/generateTemplatePDF',
    labo: {
        list: '/private/labo/list'
    }
};

const process = {
    inscription: 'inscription',
    reinscription: 'reinscription',
    etude: 'etude'
}

const messages = {
    // server error codes
    '-1': "TECHNICAL_ERROR", // event bus replay delay
    0: "TECHNICAL_ERROR",
    1: "E-mail ou mot de passe incorrect",
    2: "Adresse e-mail ou CIN déjà utilisée",
    3: "WRONG_PASSWORD",
    4: "Session expirée",
    5: "DOCUMENT_NOT_FOUND",
    6: "DOCUMENT HAS ALREADY AN OPEN TASK",
    8: "LE CODE EST EXPIRE !!!",
    9: "EMAIL INTROUVABLE",
    10: "lien invalide",
    11: "CANDIDATURE AU CYCLE DOCTORAL EST TERMINÉ",
    _200: "fichier  charger avec success",

    technical_error: "TECHNICAL_ERROR",

    user: {
        create_succeeded: "Le compte a été créé avec succès! <br/> Le mot de passe vous sera envoyé par mail, veuillez consulter votre boîte mail",
        update_succeeded: "Utilisateur mis à jour avec succès!",
        delete_succeeded: "Utilisateur supprimé avec succès!",
        reset_password: "Veuillez consulter votre boîte mail pour réintialiser votre mot de passe",
    },
    attachment: {
        upload_succeeded: "Les pièces jointes sont téléchargées avec succès!"
    },
    demandeSoutenance: {
        create_succeeded: 'Votre demande a été envoyé',
        create_failed: 'Vous avez déja une demande en attente'
    },
    etudiant: {
        complete_inscription: 'Les données enregistrées avec succès'
    }
};
const collections = {
    user: "user",
    etudiants: "etudiants"
};

const utils = {
    // current date or parse to milliseconds
    getStartOfDay: function (date) {
        return moment(date).startOf('day').valueOf();
    },
    getEndOfDay: function (date) {
        return moment(date).endOf('day').valueOf();
    },
    getMilliseconds: function (date = null) {
        return date === null ? new Date().getTime() : moment(date).utc().valueOf();

        // var milliseconds = Date.now()
        // var milliseconds = new Date().getTime()
        // var milliseconds = $.now()
        // var milliseconds = moment().utc().valueOf()

        // var timestamp = moment().utc().unix()
        // var timestamp_string = moment().utc().format("X")
    },
    // milliseconds to date as ISO8601
    getDateAsIso: function (milliseconds) {
        if (milliseconds === '' || milliseconds === undefined)
            return '';
        return moment(milliseconds).utc().toISOString();
    },
    // milliseconds to date
    getDate: function (milliseconds, format = 'DD/MM/YYYY HH:mm') {
        if (typeof milliseconds === "number")
            return moment(milliseconds).format(format);
    },
    // get date without hours
    getDateOnly: function (milliseconds, format = 'DD/MM/YYYY') {
        if (typeof milliseconds === "number")
            return moment(milliseconds).format(format);
    },
    // get Dates Difference (milliseconds)
    getDatesDifference: function (start, end) {
        if (!start || !end)
            return ''
        const startDate = moment.utc(start);
        const endDate = moment.utc(end);
        const diff = endDate.diff(startDate);
        const duration = moment.duration(diff);
        const days = duration.get('days');
        const hours = duration.get('hours');
        const minutes = duration.get('minutes');
        let time = '';
        if (days > 0)
            time += days > 10 ? `${days}j ,` : `0${days}j ,`;
        time += hours > 10 ? `${hours}h` : `0${hours}h`
        time += minutes > 10 ? `${minutes}m` : `0${minutes}m`
        return time;
    },
    // passed time from a date in milliseconds
    getTimeFromNow: milliseconds => moment(milliseconds).fromNow()
};

const consts = {
    loading: "LOADING",
    noData: "NO DATA FOUND",
    staticFiles: [
        {
            fileName: "Photo",
            fileType: "photo",
            message: "Une photo du candidat"
        },
        {
            fileName: "C.I.N / N° du Passeport",
            fileType: "cnie",
            message: "Une copie de la CIN ou une copie du Passeport pour les étrangers ayant un master marocain ;"
        },
        {
            fileName: "Reçu de préinscription",
            fileType: "recuPreinscription",
            message: "Fiche de préinscription en ligne sur le site http://cedoc.um5.ac.ma ;"
        },
        {
            fileName: "Copie conforme du Baccalauréat",
            fileType: "bac",
            message: "Copie certifiée conforme du Baccalauréat ou un diplôme équivalent ;"
        },
        {
            fileName: "Copie conforme de la Licence",
            fileType: "licence",
            message: "Copie certifiée conforme de la Licence ou un diplôme équivalent ;"
        },
        {
            fileName: "Copie conforme du Master",
            fileType: "master",
            message: "Copie certifiée conforme du Master ou un diplôme équivalent ;"
        },
        {
            fileName: "Relevés de notes du Master",
            fileType: "rvn",
            message: "Relevés de notes obtenues en Master ou un diplôme équivalent ;"
        },
        {
            fileName: "CV",
            fileType: "cv",
            message: "Un CV mettant en évidence les différentes expériences du candidat ;"
        },
        {
            fileName: "Projet de thèse",
            fileType: "projet_these",
            message: "Le projet de recherche pour la préparation de la thèse (maximum 15 pages) ;"
        },

    ],
    reinscriptionStaticFiles: [
        {
            fileName: "Etat d'avancement",
            fileType: "etat_avancement",
            message: "Etat d'avancement ;"
        },
        {
            fileName: "Rapport d'activité",
            fileType: "rapport_activite",
            message: "Rapport d'activité ;"
        },
        {
            fileName: "Reçu de réinscription",
            fileType: "reçu_reinscription",
            message: "Reçu de réinscription ;"
        },
        {
            fileName: "Reçu de paiement",
            fileType: "reçu_paiement",
            message: "Reçu de paiement ;"
        },
        {
            fileName: "Attestation de non travail",
            fileType: "attestation_non_travail",
            message: "Attestation de non travail ;"
        }
    ]
}

export default {
    fields,
    services,
    process,
    messages,
    collections,
    utils,
    consts
};
