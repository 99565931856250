import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import shared from './shared'
import VuePdfApp from "vue-pdf-app";
import moment from 'moment'
// notifications
import Notifications from 'vue-notification'
import vSelect from 'vue-select'
// modal
import VModal from 'vue-js-modal'
// vuelidate
import Vuelidate from 'vuelidate'

import Toast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.config.productionTip = false;
Vue.prototype.$SHARED = shared;
Vue.prototype.$moment = moment

Vue.use(Notifications);

Vue.use(VModal, {dialog: true});

Vue.component("vue-pdf-app", VuePdfApp);
Vue.use(Vuelidate)

//v-select
Vue.component('v-select', vSelect)

const options = {
	transition: "Vue-Toastification__fade",
	maxToasts: 20,
	newestOnTop: true,
	position: "top-right",
	timeout: 1000,
	closeOnClick: true,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: true,
	draggablePercent: 0.6,
	hideCloseButton: false,
	hideProgressBar: true,
	icon: true
};
// toast notification
Vue.use(Toast, options);


// session middleware
// check if authenticated, if not redirect to /login
router.beforeEach((to, from, next) => {
	if ( ['pwdInitialisation', 'inscription', 'landing'].includes(to.name)){
		next()
	} else if(_.isEmpty(store.state.current_user) && to.name !== 'login'){
		store.dispatchAsync("/getSessionUser", {}, true, true).then(
			data => {
				// set store user
				store.commit("SET_CURRENT_USER", data);
				store.dispatch("get_actions")
				next()
			},
			err => {
				if(to.name !== 'login') {
					next({name:'login'})
				}
			}
		);
	}
	else{
		next()
	}
});

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
