import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index.js';

Vue.use(Vuex);
let _vue = new Vue();
const isProduction = process.env.NODE_ENV === 'production';

if (!isProduction) {
	log('DEV');
	// allow ajax cross
	$.ajaxSetup({
		crossDomain: true,
		xhrFields: {
			withCredentials: true
		}
	});
}

const config = require('@/../../../config.json');

const server_addr_env_dev = window.location.protocol + '//' + window.location.hostname + ":" + config.serverPort;
const SERVER_ADDRESS = isProduction ? window.location.origin : server_addr_env_dev;

const state = {
	SERVER_ADDRESS,
	loading: false,
	current_user: {},
	current_process: '',
	steps: [],
	dossier: null
};

const getters = {
	current_step(state) {
		return state.dossier?.[state.current_process].current_step  || state.steps[0] || {}
	},
	validated_steps(state) {
		return state.dossier?.[state.current_process].validated_steps || []
	},
	dossier(state) {
		return state.dossier
	}
};

const mutations = {
	SET_LOADING(state, payload) {
		state.loading = payload
	},
	SET_CURRENT_USER(state, payload) {
		state.current_user = payload
	},
	SET_STEPS(state, payload) {
		state.steps = payload
	},
	SET_DOSSIER(state, payload) {
		state.dossier = payload
	},
	SET_CURRENT_PROCESS(state, payload) {
		state.current_process = payload
	}
};

const actions = {
	initApp({commit, dispatch}) {
		console.log('`$store.initApp`')
		commit("SET_LOADING", true);
		return new Promise((resolve, reject) => {
			dispatch("check_session").then(() => {
			//	dispatch("get_steps")
				dispatch("get_dossier").then(()=>{
					dispatch("get_steps")
					resolve();
				})

			}, () => {
				reject()
			}).finally(() => {
				commit("SET_LOADING", false)
			})
		})
	},
	check_session(context) {
		console.log('`$store.check_session`')
		return new Promise((resolve, reject) => {
			if ('_id' in context.state.current_user) {
				resolve();
			} else {
				dispatchAsync(_vue.$SHARED.services.checkSession, {}).then(data => {
					context.commit("SET_CURRENT_USER", data);
					resolve();
				}, () => {
					router.push({name: "login"});
					reject()
				});
			}
		})
	},
	get_steps({commit}) {
		console.log('`$store.get_steps`')
		dispatchAsync(_vue.$SHARED.services.action.list, { query: {_id: state.current_process}}).then((data) => {
			const steps = data[0].steps
			commit('SET_STEPS', steps)
		})
	},
	get_dossier({commit, state, dispatch}) {
		console.log('`$store.get_dossier`')
		return new Promise((resolve, reject) => {
			dispatchAsync(_vue.$SHARED.services.etudiantDossier, {_id: state.current_user._id}).then((dossier) => {
				dossier ? commit('SET_DOSSIER', dossier) : commit('SET_DOSSIER', {})

				dispatch('set_current_process')
				resolve()
			},reason => reject())
		});
	},
	set_dossier({commit, state}, payload) {
		console.log('`$store.set_dossier`')
		// TODO: set the right API
		dispatchAsync(_vue.$SHARED.services.etudiantDossier, payload).then((newDossier) => {
			commit('SET_DOSSIER', newDossier)
		})
	},
	set_current_process({commit, state}, payload) {
		console.log('`$store.set_current_process`')
		commit('SET_CURRENT_PROCESS', getProcess(state.dossier))

	}
};

let store = new Vuex.Store({
	state,
	getters,
	mutations,
	actions
});

export default store;

/**
 * @param url <String> request url
 * @param data <JSON> request data
 * @param use_loader <Boolean> show the application loader while request is in progress or not
 * @param notifyOnFailure <Boolean> if true and request replay.succeeded=false show notification then reject the code else just reject the code
 * @param debug <Boolean> console request data
 * @description send http request
 *
 */
function dispatchAsync(url, data, use_loader = false, notifyOnFailure = true, debug = true) {
	const stamp = new Date().getTime() + "-" + Math.round(Math.random() * 1000);
	console.log(`[${stamp}] $store.dispatchAsync...`);
	data = JSON.stringify(data);
	url = SERVER_ADDRESS + url;
	return new Promise((resolve, reject) => {
		use_loader && store.commit('SET_LOADING', true);
		let headers = {"Content-Encoding": "gzip", "Content-Type": "application/json; charset=utf-8"};
		$.ajax({
			url,
			headers,
			data,
			type: "POST",
			datatype: "json",
			complete: () => {
				// hide loader
				use_loader && store.commit('SET_LOADING', false);
			}
		}).done(resp => {
			console.log(`[${stamp}] $store.dispatchAsync\n\turl: %s, \n\tdata: %o\n\tresp: %o`, url, debug ? data : '???', resp);
			if (resp.succeeded) {
				resolve(resp.data);
			} else {
				// if session is expiered
				if (resp.message === 4) {
					notify('error', 4)
					router.push({name: "login"});
				}
				if (notifyOnFailure) {
					notify('error', resp.message)
				}
				reject(resp.message);
			}
		}).fail(err => {
			console.error(`[${stamp}] AjaxFail\n$store.dispatchAsync\n\turl: %s, \n\tdata: %o\n\terr: %o`, url, debug ? data : '???', err);
			reject(0);
		});
	})
}

/**
 * @param url <String> request url
 * @param data <JSON> request data
 * @param use_loader <Boolean> show the application loader while request is in progress or not
 * @param notifyOnFailure <Boolean> if true and request replay.succeeded=false show notification then reject the code else just reject the code
 * @param debug <Boolean> console request data
 * @description upload file
 */
function uploadFile(url, formData, use_loader = false, notifyOnFailure = true, debug = true) {
	const stamp = new Date().getTime() + "-" + Math.round(Math.random() * 1000);
	console.log(`[${stamp}] $store.uploadFile...`);
	return new Promise((resolve, reject) => {
		use_loader && store.commit('SET_LOADING', true);
		url = SERVER_ADDRESS + url;
		$.ajax({
			url,
			type: "POST",
			data: formData,
			xhrFields: { withCredentials: true },
			cache: false,
			contentType: false,
			enctype: "multipart/form-data",
			processData: false,
			complete: () => {
				// hide loader
				use_loader && store.commit('SET_LOADING', false);
			}
		}).done(resp => {
			log("uploadFile resp", resp)
			console.log(`[${stamp}] $store.uploadFile\n\turl: %s, \n\tdata: %o\n\tresp: %o`, url, debug ? formData : '???', resp);
			if (resp.succeeded) {
				resolve(resp.data);
			} else {
				// if session is expiered
				if (resp.message === 4) {
					notify('error', 4)
					router.push({name: "login"});
				}
				if (notifyOnFailure) {
					notify('error', resp.message)
				}
				reject(resp.message);
			}
		}).fail(err => {
			console.error(`[${stamp}] AjaxFail\n$store.uploadFile\n\turl: %s, \n\tdata: %o\n\terr: %o`, url, debug ? formData : '???', err);
			reject(0);
		});
	})
}

store.openDocument = (url) => {
	console.log('openDocument, url:', url)
	url = SERVER_ADDRESS + url
	console.log('openDocument, url:', url)
	let a = document.createElement('a')
	a.href = url
	a.target = '_blank'
	document.body.appendChild(a)
	a.click()
	document.body.removeChild(a)
}

store.isPDF = (name) => {
	return name.substring(name.lastIndexOf('.')+1)==="pdf"
}

store.dispatchAsync = dispatchAsync;
store.uploadFile = uploadFile;


// TODO remove this
const LAST_NOTIFICATION = {
	code: '',
	date: 0
};

// warn , error, success, info
function notify(type, code) {
	const currentTime = new Date().getTime();
	const sameCode = LAST_NOTIFICATION.code === code
	const sameTime = currentTime - LAST_NOTIFICATION.date < 3000;
	//_vue.$notify({group: 'user-message', clean: true})
	if (sameCode && sameTime) {
	} else {
		_vue.$notify({
			group: 'user-message',
			type: type, // warn , error, success, info
			text: _vue.$SHARED.messages[code]
		});
	}
	LAST_NOTIFICATION.code = code;
	LAST_NOTIFICATION.date = currentTime;
}

function getProcess(dossier) {
	console.log("getProcess ....", dossier)
	let process = ''
	if (_.isEmpty(dossier)) {
		console.log("dossier empty ==> process inscription")
		process = _vue.$SHARED.process.inscription
	} else if (dossier.reinscription && dossier.reinscription.status && dossier.reinscription.status === 'validated') {
		console.log("student already reinscrit ==> process etude")
		process = _vue.$SHARED.process.etude
	} else if (dossier.reinscription) {
		console.log("student not reinscrit ==> process reinscription")
		process = _vue.$SHARED.process.reinscription
	} else if (dossier.inscription && dossier.inscription.status && dossier.inscription.status === 'validated') {
		console.log("student already inscrit ==> process etude")
		process = _vue.$SHARED.process.etude
	} else {
		console.log("==> process inscription")
		process = _vue.$SHARED.process.inscription
	}
	console.log("getProcess return : ==> ", process)
	return process
}
